import * as React from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import "../../styles/main.scss";
import { Helmet } from "react-helmet";
import AppleTouchIcon from "../../images/favicons/apple-touch-icon.png";
import Icon32 from "../../images/favicons/favicon-32x32.png";
import Icon16 from "../../images/favicons/favicon-16x16.png";
import Favicon from "../../images/favicons/favicon.ico";
import NerdwalletFavicon from "../../images/favicons/nerdwallet_favicon.png";
import MetaImage from "../../images/common/uprise-partner-meta-image.png";
import { NERDWALLET_SEO, SEO } from "../../data/commonData";
import styled from "styled-components";
import { Spacing } from "../../styles/styleConstants";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";

const NerdwalletLayout = (props) => {
  const {
    children,
    includeHeader = true,
    includeFooter = true,
    style,
    className,
    footerStyle,
  } = props ?? {};
  const { description, title, twitterImage } = NERDWALLET_SEO;
  return (
    <BodyWrapper style={style} className={className}>
      <Helmet>
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={NerdwalletFavicon}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={NerdwalletFavicon}
        />
        <link rel="shortcut icon" href={NerdwalletFavicon} />
        <meta property="og:image" content={MetaImage} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={description} />
        <meta name="twitter:card" content={"summary_large_image"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={twitterImage} />
        <meta name="og:title" content={title} />
        <meta name="og:type" content={"website"} />
        <meta name="og:description" content={description} />
        <meta name="og:url" content={"https://uprise.us/"} />
      </Helmet>
      {includeHeader && <Header />}
      {children}
      {includeFooter && (
        <FooterWrapper style={footerStyle}>
          <Footer />
        </FooterWrapper>
      )}
    </BodyWrapper>
  );
};

export default NerdwalletLayout;

const BodyWrapper = styled.div`
  overflow: hidden;
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

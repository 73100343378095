import React from "react";
import styled from "styled-components";

import Image from "../common/Image";
import ExternalLink from "../common/externalLink";
import SemiBoldTitle from "../common/SemiBoldTitle";
import {
  FORM_ADV,
  NERDWALLET_PRIVACY_URL,
  NERDWALLET_TERMS_URL,
  PRIVACY_URL,
  TERMS_URL,
} from "../../utils/commonConst";
import {
  Breakpoints,
  BreakpointsDown,
  Colors,
} from "../../styles/styleConstants";

export default function NerdwalletFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <TopRow>
        <LogoWrapper>
          <Image
            className="footerLogo"
            src="nerdwallet/nerdwallet_footer_logo.png"
            alt="Uprise"
            style={{
              width: "250px",
              display: "inline-block",
            }}
            imgStyle={{ objectFit: "contain" }}
          />
        </LogoWrapper>
        <FooterLinks>
          <ExternalLink href={NERDWALLET_TERMS_URL} name="privacy">
            <SemiBoldTitle style={{ color: Colors.neutralMedDark }}>
              NerdWallet Terms of service
            </SemiBoldTitle>
          </ExternalLink>
          <ExternalLink href={NERDWALLET_PRIVACY_URL} name="privacy">
            <SemiBoldTitle style={{ color: Colors.neutralMedDark }}>
              NerdWallet Privacy policy
            </SemiBoldTitle>
          </ExternalLink>
          <ExternalLink href={TERMS_URL} name="terms">
            <SemiBoldTitle style={{ color: Colors.neutralMedDark }}>
              Uprise Terms of service
            </SemiBoldTitle>
          </ExternalLink>
          <ExternalLink href={PRIVACY_URL} name="privacy">
            <SemiBoldTitle style={{ color: Colors.neutralMedDark }}>
              Uprise Privacy policy
            </SemiBoldTitle>
          </ExternalLink>
          {/* <ExternalLink href={FORM_ADV} name="adv">
            <SemiBoldTitle style={{ color: Colors.neutralMedDark }}>
              Form ADV
            </SemiBoldTitle>
          </ExternalLink> */}
          <div style={{ padding: "20px" }}>
            <SemiBoldTitle
              style={{ color: Colors.neutralMedDark }}
            >{`© ${currentYear}`}</SemiBoldTitle>
          </div>
        </FooterLinks>
      </TopRow>
      <LegalDisclaimerWrapper>
        <div>
          <div>
            Financial planning for NerdWallet Coach, other than advice regarding
            investing in securities, and financial planning tools are offered by
            Ontrail, Inc., doing business as “Uprise”, a corporation registered
            in Delaware, United States. Investment advisory and financial
            planning including advice regarding securities are provided by
            Uprise Advisers LLC (“Uprise Advisers”), a registered investment
            adviser and wholly owned subsidiary of Ontrail, Inc. Uprise Advisers
            may only transact business in those states in which it is
            registered, or qualifies for an exemption or exclusion from
            registration requirements.
          </div>
          <br />
          <div>
            Uprise’s website is limited to the dissemination of general
            information pertaining to its financial planning and advisory
            services (which expressly excludes advice regarding securities).
            This website and information provided herein are not intended to
            provide investment, tax, or legal advice. All testimonials on this
            website relate to Uprise, not Uprise Advisers or its services.
          </div>
        </div>
        <br />
        <div>
          Ontrail, Inc. dba Uprise © {currentYear}. Ontrail, Inc. is registered
          in Delaware, United States.
        </div>
      </LegalDisclaimerWrapper>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  text-align: center;
  background-color: ${Colors.nerdwalletDarkBlue};
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const LogoWrapper = styled.div`
  padding-left: 24px;
  padding-top: 24px;
`;

const LegalDisclaimerWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.neutralMedDark};
  padding: 32px 250px;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 32px 8px;
  }
`;

const FooterLinks = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding-right: 24px;

  @media (max-width: ${BreakpointsDown.sm}) {
    flex-direction: column;
    align-items: flex-start;

    > a {
      padding-top: 10px;
      padding-bottom: 10px;
      display: inline-block;
    }
  }
`;

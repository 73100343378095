import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";

import NerdwalletCtaButton from "./NerdwalletCTAButton";
import Image from "../common/Image";
import SemiBoldTitle from "../common/SemiBoldTitle";
import {
  NERDWALLET_LOGIN,
  NERDWALLET_SIGNUP,
  OTHER_APP_PAGES,
} from "../../utils/commonConst";
import { getAppUrlByModule, isEmpty, navigateTo } from "../../utils/commonUtil";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";

export default function NerdwalletHeader(props) {
  const {
    source,
    hideMenus = false,
    loginUrl = NERDWALLET_LOGIN,
    signupUrl = NERDWALLET_SIGNUP,
  } = props ?? {};
  const [paramString, setParamString] = useState("");
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <HeaderWrapper>
      <NavBarRootWrapper>
        <Navbar.Brand
          href={`/${isEmpty(source) ? "" : `${source}/`}${paramString}`}
        >
          <Image
            src="nerdwallet/nerdwallet_coach_logo.png"
            alt="NerdWallet Coach"
            className="HeaderDesktopLogo"
            imgStyle={{ objectFit: "contain" }}
          />
          <Image
            src="nerdwallet/nerdwallet_coach_logo.png"
            alt="Nerdwallet Coach mobile"
            className="HeaderMobileLogo"
            imgStyle={{ objectFit: "contain" }}
          />
        </Navbar.Brand>
        <MobileMenu onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <Image
              src="home/close-icon.png"
              alt="Close Icon"
              className="closeImage"
              imgStyle={{ objectFit: "contain" }}
            />
          ) : (
            <Image
              src="home/menu-nav-ic.png"
              alt="Menu Bar Icon"
              className="menuBarImage"
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </MobileMenu>
        {hideMenus === false && (
          <NavLinkWrapper>
            <Navbar id="basic-navbar-nav" style={{ gap: "16px" }}>
              <NerdwalletCtaButton
                onClick={(evt) => {
                  navigateTo(loginUrl);
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                style={{
                  minHeight: "40px",
                  cursor: "pointer",
                  padding: "8px 12px",
                  fontSize: "16px",
                }}
                ctaType="secondary"
              >
                Log in
              </NerdwalletCtaButton>
              <NerdwalletCtaButton
                onClick={(evt) => {
                  navigateTo(signupUrl);
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                style={{
                  minHeight: "40px",
                  cursor: "pointer",
                  padding: "8px 12px",
                  fontSize: "16px",
                }}
                ctaType="primary"
              >
                Get started
              </NerdwalletCtaButton>
            </Navbar>
          </NavLinkWrapper>
        )}
      </NavBarRootWrapper>
      {toggle && (
        <NavLinkMobileOnlyWrapper>
          <Navbar id="basic-navbar-nav">
            <Nav className="flex-column w-100">
              <MobileNavLinkWrapper>
                <NerdwalletCtaButton
                  onClick={(evt) => {
                    navigateTo(loginUrl);
                    evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  style={{
                    minHeight: "40px",
                    cursor: "pointer",
                    padding: "8px 12px",
                    fontSize: "16px",
                  }}
                  ctaType="secondary"
                  isStretchToFullWidth={true}
                >
                  <SemiBoldTitle style={{ color: Colors.nerdwalletBlue }}>
                    Log in
                  </SemiBoldTitle>
                </NerdwalletCtaButton>
                <NerdwalletCtaButton
                  onClick={(evt) => {
                    navigateTo(signupUrl);
                    evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  style={{
                    minHeight: "40px",
                    cursor: "pointer",
                    padding: "8px 12px",
                    fontSize: "16px",
                    background: Colors.nerdwalletBlue,
                  }}
                  ctaType="primary"
                  isStretchToFullWidth={true}
                >
                  <SemiBoldTitle style={{ color: Colors.white }}>
                    Get started
                  </SemiBoldTitle>
                </NerdwalletCtaButton>
              </MobileNavLinkWrapper>
            </Nav>
          </Navbar>
        </NavLinkMobileOnlyWrapper>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  position: relative;
`;

const NavBarRootWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
  height: 72px;

  .HeaderDesktopLogo {
    width: 366px;
    height: 160px;

    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
  .HeaderMobileLogo {
    width: 200px;
    height: 160px;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 0px 24px;
  }

  .nav-link {
    font-weight: 600 !important;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    style: normal;

    &:hover {
      border-bottom: 2px solid #317af9;
    }
  }

  .community-nav-link {
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
`;

const NavLinkMobileOnlyWrapper = styled.div`
  position: absolute;
  margin-top: 1px;
  background: ${Colors.white};
  z-index: 5;
  padding: ${Spacing.sm};
  width: 100%;
  display: none;

  .about-us {
    margin: ${Spacing.xs} ${Spacing.md} ${Spacing.md};
    padding: ${Spacing.md} 0 !important;
    border-top: 1px solid #d1d3d4;
    border-bottom: 1px solid #d1d3d4;
  }

  .partnership {
    margin: ${Spacing.md} ${Spacing.md} 0;
    padding: ${Spacing.md} 0 !important;
    border-top: 1px solid #d1d3d4;
  }

  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`;

const NavLinkWrapper = styled.div`
  display: flex;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`;

const MobileNavLinkWrapper = styled.div`
  display: flex;
  gap: ${Spacing.sm};
  justify-content: center;
  padding: ${Spacing.md};
`;

const MobileMenu = styled.div`
  display: none;
  cursor: pointer;

  .menuBarImage {
    width: 40px;
    height: 40px;
  }

  .closeImage {
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`;
